<template>
  <div class="lucky">
    <ShopNavBar title="无损幸运购" :autoBack="false" @onBack="onBack">
      <template #right>
        <img src="@/assets/img/shop/fenxiang.png" width="19" />
      </template>
    </ShopNavBar>

    <div class="header">
      <img src="@/assets/img/shop/banner.png" class="banner" @click="tolink('luckyBuyRule')" />
      <van-tabs v-model:active="active" @click-tab="tabsChange">
        <van-tab name="0" title="全部"></van-tab>
        <van-tab name="1" title="即将结束"></van-tab>
        <van-tab name="2" title="中奖名单"></van-tab>
        <van-tab name="3" title="已参与"></van-tab>
      </van-tabs>
    </div>

    <div class="scroll">
      <!-- 全部 -->
      <div class="q-mt-md" v-if="active == 0">
        <van-list v-if="goodslist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false"
          offset="10" finished-text="没有更多了" @load="onLoad">
          <GoodsList :list="goodslist" :autoToDetails="false">
            <template v-slot:default="slot">
              <div class="goods-name">{{ slot.slotValue.shopGoods.name }}</div>
              <div class="goods-label good-term">第{{ slot.slotValue.number }}期</div>
              <div class="goods-price">￥{{ slot.slotValue.shopGoods.price }} <span style="font-size: 13px;">(${{ slot.slotValue.priceText }})</span> </div>
              <div class="goods-label">
                揭晓进度
                <span class="text-shopcolor">{{ (slot.slotValue.speed * 100).toFixed(2) }}%</span>
              </div>
              <q-linear-progress :value="slot.slotValue.speed" rounded color="shopcolor" class="q-my-xs" />
              <div class="goods-label text-shopcolor">还差{{ slot.slotValue.residue }}份揭晓</div>
              <div class="join-btn" @click="toDetails(slot.slotValue.shopLucky.id)">立即参与</div>
            </template>
          </GoodsList>
        </van-list>
        <van-empty description="暂无商品" v-if="goodslist.length == 0" />
      </div>

      <!-- 中奖名单 -->
      <div class="prize" v-if="active == 2">
        <div class="prize-header">
          <div>
            <div class="prize-title">恭喜各位LBD布道者中奖</div>
            <div class="prize-label">商家将即日为您发货，请耐心等待~</div>
          </div>
          <img src="@/assets/img/shop/prize.png" width="115" />
        </div>
        <van-list v-if="resultlist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false"
          offset="10" finished-text="没有更多了" @load="onLoad">
          <div class="prize-list">
            <div class="prize-item" v-for="item in resultlist" :key="item.id">
              <div class="row items-center">
                <img src="@/assets/img/logo2.png" class="prize-item-tx">
                <div>{{ abbr(item.winnerUserid, 4, 4) }}</div>
              </div>
              <div class="row q-mt-sm">
                <img :src="getimg(item.goodsImg)" width="82" />
                <div class="col q-ml-sm">
                  <div class="goods-name" style="font-size: 15px;">{{ item.goodsName }}</div>
                  <div class="q-mt-xs text-shopcolor" style="font-size: 13px;">期数：第{{ item.number }}期</div>
                  <div class="q-mt-xs" style="font-size: 13px;">中奖支付编号：{{ item.winnerNumber }}</div>
                  <div class="q-mt-xs" style="font-size: 13px;">开奖时间：{{ item.openTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty description="暂无记录" v-if="resultlist.length == 0" />
      </div>

      <!-- 订单 -->
      <div v-if="active == 3">
        <div class="order-tabs">
          <van-tabs v-model:active="orderTabsActive" type="card" @click-tab="tabsChange">
            <van-tab :title="item.text" :name="item.value" v-for="item in orderTabs" :key="item.value"></van-tab>
          </van-tabs>
        </div>
        <van-list v-if="orderlist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false"
          offset="10" finished-text="没有更多了" @load="onLoad">
          <div class="order" v-for="item in orderlist" :key="item.id">
            <div class="order-head">
              <div style="font-size: 13px;">{{ item.orderNo }}</div>
              <div class="row">
                <q-chip square color="orange" dense text-color="white" v-if="item.stateOrder == 0">{{ item.stateOrderName }}</q-chip>
                <q-chip square color="orange" dense text-color="white" v-if="item.stateLucky == 0">{{ item.stateLuckyName }}</q-chip>
                <q-chip square color="grey" dense text-color="white" v-if="item.stateLucky == 1">{{ item.stateLuckyName }}</q-chip>
                <q-chip square color="positive" dense text-color="white" v-if="item.stateLucky == 2 || item.stateLucky == 3">{{ item.stateLuckyName }}</q-chip>
              </div>
            </div>
            <div class="row q-mt-xs">
              <img :src="getimg(item.goodsModelImg)" width="70" />
              <div class="col q-ml-sm">
                <div class="goods-name">{{ item.goodsName }}</div>
                <div class="goods-label text-grey">规格：{{ item.goodsModelName }}</div>
                <div class="row justify-between q-mt-sm">
                  <div class="text-shopcolor">￥{{ item.goodsPrice }}</div>
                  <div>{{ item.amountPay }}{{ item.payCoinName }}</div>
                </div>
              </div>
            </div>
            <div class="q-mt-sm row justify-end">
              <div class="min-btn" @click="tolink('luckyBuyPay?id=' + item.id)" v-if="item.stateOrder == 0">支付</div>
              <div class="min-btn" @click="tolink('luckyBuyCash?id=' + item.id)" v-if="item.stateLucky == 2">领奖</div>
            </div>
          </div>
        </van-list>
        <van-empty description="暂无订单" v-if="orderlist.length == 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Tab, Tabs, List, Empty } from 'vant';
import { ref } from 'vue';
import GoodsList from './components/GoodsList.vue'

export default {
  components: {
    [NavBar.name]: NavBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Empty.name]: Empty,
    GoodsList
  },
  setup() {
    return {
      us: ref(null),
      active: ref("0"),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      goodslist: ref([]),
      orderlist: ref([]),
      resultlist: ref([]),

      orderTabsActive: ref(0),
      orderTabs: [
        {text: '待开奖', value: 0},
        {text: '未中奖', value: 1},
        {text: '已中奖', value: 2},
        {text: '已领奖', value: 3},
      ]
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if(this.$route.query.active) {
      this.active = this.$route.query.active
    }
    // this.getGoods()
    this.tabsChange()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "upload/" + img;
			}
		},
    onBack() {
      this.$router.replace('shop')
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    tabsChange() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.orderlist = []
      this.goodslist = []
      this.resultlist = []
      if(this.active == 0) this.getGoods()
      if(this.active == 2) this.getResult()
      if(this.active == 3) this.getlist()
    },
    toDetails(id) {
      this.tolink('shopDetails?lx=2&id=' + id)
    },
    // 商品加载下一页
    onLoad() {
      this.loading = true
      this.pageIndex++
      if(this.active == 0) this.getGoods()
      if(this.active == 2) this.getResult()
      if(this.active == 3) this.getlist()
    },
    getGoods() {
      let _this = this
      _this.$request.post(
        "api/ShopLucky/List",
        {
          pageIndex: _this.pageIndex,
          pageSize: 10,
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 100) {
            let data = res.data.data
            console.log(data)
            data.forEach(item => {
              item.img = item.shopGoods.dbShopGoodsModel[0].img
              item.shopGoods.price = item.shopGoods.dbShopGoodsModel[0].price
              item.speed = item.shopLuckyResult.buyCount / item.shopLuckyResult.buyCountMax
              item.number = item.shopLuckyResult.number
              item.residue = item.shopLuckyResult.buyCountMax - item.shopLuckyResult.buyCount
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.goodslist.push(...data)
            // console.log(_this.goodslist)
          }
        }
      )
    },
    getResult() {
      let _this = this
      _this.$request.post(
        "api/ShopLuckyResult/List",
        {
          pageIndex: _this.pageIndex,
          pageSize: 10,
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 100) {
            let data = res.data.data
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.resultlist.push(...data)
            // console.log(_this.resultlist)
          }
        }
      )
    },
    getlist() {
      let _this = this
      _this.$request.post(
        "api/ShopLuckyOrder/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          stateOrder: "",
          stateLucky: _this.orderTabsActive
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.goodsModelImg = item.goodsModelImg.split(',')[0]
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.orderlist.push(...data)
            // console.log(_this.orderlist)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.lucky {
  min-height: 100vh;
  background-color: rgba(242, 242, 242, 1);
}

.header {
  background-color: #fff;
  overflow: hidden;
}

.prize {
  padding: 30px 20px 20px;
  background: rgba(252, 240, 227, 0.7);
}

.prize-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prize-title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(254, 44, 45, 1);
}

.prize-label {
  color: rgba(128, 128, 128, 1);
  font-size: 13px;
}

.prize-list {
  position: relative;
  margin-top: -10px;
  background-color: #fff;
}

.prize-item {
  padding: 16px 20px;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(245, 245, 245, 1);
}

.prize-item:last-child {
  border: none;
}

.prize-item-tx {
  margin-right: 20px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.banner {
  width: 100%;
  display: block;
  transform: scale(1.1);
}

.goods-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.goods-price {
  padding: 2px 0 0;
  color: rgba(255, 45, 46, 1);
  font-size: 18px;
}

.good-term {
  color: rgba(166, 166, 166, 1);
}

.goods-speed span {
  margin-left: 4px;
  color: rgba(255, 45, 46, 1);
}

.goods-label {
  font-size: 12px;
}

.join-btn {
  margin-top: 10px;
  text-align: center;
  line-height: 32px;
  height: 32px;
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.order {
  margin: 10px 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.order-head {
  margin-bottom: 10px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(207, 199, 199, .4);
}

.min-btn {
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.scroll {
  height: calc(100vh - 235px);
  overflow-y: auto;
}

.order-tabs {
  margin: 10px 10px 0;
}
</style>